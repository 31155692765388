import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { useEffect, useState } from "react";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import "../../assets/Css/mainOriginalList.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { accessToken } from "../../Constant/token";
import config from "../../config";

const AdyenCreditPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const storeCode = searchParams.get("store");
  const customerEmail = searchParams.get("email");
  const cartId = localStorage.getItem("cartId");

  const [showToaster, setShowToaser] = useState({
    show: false,
    text: "",
    type: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowToaser({
      show: false,
      text: "",
      type: "",
    });
  };

  useEffect(() => {
    const configuration = {
      locale: "en-US",
      environment: "test",
      clientKey: "test_76BSLKJ56FB7PMG3CZ3TTPSJ3AOYC7XS",
    };

    const initializeCheckout = async () => {
      const checkout = await AdyenCheckout(configuration);

      const card = checkout.create("card", {
        hasHolderName: true,
        holderNameRequired: true,
        showPayButton: true,

        onChange: (state) => {
          console.log("state in onchange", state);
        },
        onSubmit: (state, component) => {
          onSubmitData(state, component);
        },
      });

      card.mount("#card-container");
    };

    initializeCheckout();
  }, []);

  const onSubmitData = async (values, component) => {
    component.setStatus("loading");
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${cartId}/payment-information`;

    const data = {
      cartId: cartId,
      paymentMethod: {
        method: "adyen_cc",
        additional_data: {
          stateData: JSON.stringify(values.data),
          guestEmail: customerEmail,
          cc_type: values.data.paymentMethod.brand,
          number_of_installments: "",
        },
      },
      email: customerEmail,
    };

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "post",
        url,
        headers,
        data: data,
      });

      // console.log("response", response.data);
      getPaymentStatus(response.data);
    } catch (error) {
      console.error("error", error);
      setShowToaser({
        show: true,
        text: "Pagamento non riuscito, riprova",
        type: "error",
      });
      localStorage.removeItem("cartId");
      setTimeout(() => {
        navigate(`/`);
      }, 6000);
    } finally {
      component.setStatus("ready");
    }
  };

  const getPaymentStatus = async (id) => {
    let url =
      config.placeholderApiUrl + `/${storeCode}/V1/adyen/orders/payment-status`;
    const data = {
      orderId: id,
    };
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "post",
        url,
        headers,
        data: data,
      });
      const responseData = JSON.parse(response.data);
      localStorage.removeItem("cartId");
      if (
        responseData.resultCode === "Refused" ||
        responseData.resultCode === "Error"
      ) {
        setShowToaser({
          show: true,
          text: "Pagamento non riuscito, riprova",
          type: "error",
        });
        setTimeout(() => {
          navigate(`/`);
        }, 6000);
      } else {
        setShowToaser({
          show: true,
          text: "Pagamento riuscito",
          type: "success",
        });
        setTimeout(() => {
          navigate(
            `/thankyou?store=${storeCode}&adyen=adyen&orderId=${responseData.orderId}`
          );
        }, 5000);
      }

      // console.log("responseData", responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showToaster.show}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={showToaster.type}
          sx={{ width: "100%" }}
        >
          {showToaster.text}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          padding: "20px 15px 20px 15px",
        }}
        component={"div"}
        className="adyen-payment-form"
      >
        <div id="card-container" className="adyen-card-container" />
      </Box>
    </>
  );
};

export default AdyenCreditPayment;
