import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { themeColors } from "../../Constant/themeColor";
import { Avatar, Badge, Box, Chip, Divider } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

import { useLocation, useNavigate } from "react-router-dom";
import { themeFonts } from "../../Constant/themeFont";
import { PATH_COFFEE_LIST_ORIGINAL } from "../../App";
import { accessToken } from "../../Constant/token";
import SearchAppBar from "../../components/SearchAppBar";
import axios from "axios";
import config from "../../config";
import ProgressIndicator from "../../components/ProgressIndicator";
import { baseImageUrl } from "../../Constant/constData";

const shouldShow = () => {
  if (window.location.pathname.startsWith(PATH_COFFEE_LIST_ORIGINAL)) {
    return true;
  }
  return false;
};

const Header = (props) => {
  const navigate = useNavigate();
  const showHeader = window.location.pathname;

  // console.log("props in header", props);

  const [loading, setLoading] = useState(false);
  const [storeDetails, setStoreDetails] = useState();
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(3);
  const [showSearch, setShowSearch] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storeCode = searchParams.get("store");

  useEffect(() => {
    if (showHeader !== "/") {
      getAllHeaderData();
      getStoreDetails();
    }
  }, [storeCode]);

  const getStoreDetails = async () => {
    try {
      const url = config.placeholderApiUrl + `/${storeCode}/V1/storedetail/`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const { data } = await axios({
        method: "get",
        url,
        headers,
      });

      setStoreDetails(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAllHeaderData = async () => {
    setLoading(true);
    try {
      const url =
        config.placeholderApiUrl + `/${storeCode}/V1/categories/product/list/`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios({
        method: "get",
        url,
        headers,
      });
      const data = response.data;

      setCategoriesData(data.items);
      props.getAllCategoryData(data.items);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ProgressIndicator />}
      {showHeader !== "/" && (
        <AppBar position="static" sx={{ boxShadow: "none" }}>
          <Box
            onClick={() => navigate("/")}
            component={"div"}
            sx={{ backgroundColor: themeColors.text.primary }}
          >
            <Container
              maxWidth="md"
              sx={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <Toolbar sx={{ padding: "0px !important" }}>
                <LocationOnIcon
                  sx={{
                    color: "white",
                    margin: "0px 16px 0px 16px",
                    fontSize: 24,
                  }}
                />
                <div>
                  <Typography
                    variant="h6"
                    fontFamily={themeFonts.medium}
                    sx={{
                      color: themeColors.common.white,
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    {storeDetails?.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontFamily={themeFonts.medium}
                    sx={{
                      color: themeColors.common.disabled,
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      "@media (max-width: 768px)": {
                        fontSize: "13px",
                      },
                    }}
                  >
                    {storeDetails?.address}
                  </Typography>
                </div>
              </Toolbar>
            </Container>
          </Box>
          <Box
            component={"div"}
            sx={{ backgroundColor: themeColors.common.white }}
          >
            <Container maxWidth="md">
              <Toolbar sx={{ padding: "0px !important" }}>
                {showSearch ? (
                  <SearchAppBar
                    showSearch={showSearch}
                    setShowSearch={setShowSearch}
                    storeCode={storeCode}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ cursor: "pointer" }}>
                      <Box
                        component={"img"}
                        onClick={() => {
                          navigate(`/originalList?store=${storeCode}`);
                        }}
                        src={`${baseImageUrl}/Logo.png`}
                        alt="Nespresso Logo"
                        sx={{
                          height: "50px",
                          width: "134px",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <Box
                        component={"img"}
                        onClick={() => setShowSearch(true)}
                        src={`${baseImageUrl}/Search.png`}
                        alt="searchLogo"
                      />
                      <Badge
                        badgeContent={props.cartCount?.items.length}
                        color="success"
                      >
                        <Box
                          component={"img"}
                          alt="cartLogo"
                          onClick={() => {
                            navigate(`/cartList?store=${storeCode}`);
                          }}
                          src={`${baseImageUrl}/Bag.png`}
                        />
                      </Badge>
                    </Box>
                  </Box>
                )}
              </Toolbar>
            </Container>
          </Box>

          <Divider sx={{ backgroundColor: themeColors.common.surfaceGray }} />
          {shouldShow() && !showSearch && (
            <>
              {/* <Box
                component={"div"}
                sx={{ backgroundColor: themeColors.common.white }}
              >
                <Toolbar variant="dense" sx={{ padding: "0px !important" }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                    }}
                  >
                    <Container maxWidth="md">
                      <TabContext value={selectedTab}>
                        <Box
                          className="tablist"
                          sx={{
                            "& .MuiTabs-indicator": {
                              background: themeColors.text.primary,
                            },
                          }}
                        >
                          <TabList
                            onChange={handleChange}
                            aria-label="Categories"
                          >
                            {categoriesData?.map((element) => {
                              return (
                                <Tab
                                  key={element.id}
                                  className="tabPadBtn"
                                  sx={{
                                    "&.Mui-selected": {
                                      color: themeColors.text.primary,
                                    },
                                    margin: "0px 24px 0px 0px",
                                  }}
                                  label={
                                    <Box
                                      className="tabimgfont"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        component={"img"}
                                        src={element.image}
                                        alt="icon"
                                        sx={{
                                          marginRight: "5px",
                                          height: "24px",
                                          width: "24px",
                                        }}
                                      />
                                      <Typography
                                        fontFamily={themeFonts.medium}
                                        fontSize={"14px"}
                                        fontWeight={700}
                                        lineHeight={"16.8px"}
                                        letterSpacing={"0px"}
                                      >
                                        {element.name}
                                      </Typography>
                                    </Box>
                                  }
                                  value={element.id}
                                />
                              );
                            })}
                          </TabList>
                        </Box>
                      </TabContext>
                    </Container>
                  </Box>
                </Toolbar>
              </Box> */}
              <Box
                component={"div"}
                sx={{ backgroundColor: themeColors.common.white }}
              >
                <Toolbar
                  variant="dense"
                  sx={{
                    padding: "8px 0px 8px 16px !important",
                    boxShadow: "0px 4px 8px 0px rgba(23, 23, 26, 0.08)",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Container
                      maxWidth="md"
                      sx={{
                        display: "flex",
                        flexWrap: "nowrap",
                        overflow: "scroll",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                    >
                      {categoriesData.map((parent) => {
                        return (
                          <>
                            {parent.child_category.map((element, index) => {
                              return (
                                <>
                                  <Chip
                                    key={index}
                                    sx={{
                                      marginRight: "8px",
                                      borderRadius: "360px",
                                      borderColor:
                                        themeColors.common.chipBorderColor,
                                      height: "auto",
                                      padding: "4px 16px 4px 4px",
                                      backgroundColor:
                                        themeColors.common.pillWhite,
                                    }}
                                    avatar={
                                      <Avatar
                                        src={element.image}
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                          marginLeft: "0px",
                                        }}
                                      >
                                        N
                                      </Avatar>
                                    }
                                    label={
                                      <Typography
                                        fontFamily={themeFonts.regular}
                                        fontSize="14px"
                                        fontWeight={400}
                                        lineHeight="normal"
                                        letterSpacing="1px"
                                      >
                                        {element.name}
                                      </Typography>
                                    }
                                    variant="outlined"
                                  />
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </Container>
                  </Box>
                </Toolbar>
              </Box>
            </>
          )}
        </AppBar>
      )}
    </>
  );
};

export default Header;
